<template>
  <!-- begin::Users edit for Superadmin role-->
  <div>
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/perfil-primary.svg"
      />
      <div>
        {{ $t("USERS.TITLE_EDIT") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <div class="manager-subtitle">
        {{ $t("USERS.SUBTITLE") }}
      </div>

      <v-form class="form" ref="formUserEdit" v-model="Valid" lazy-validation>
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("USERS.STATUS") }}
            </label>
            <select v-model="Fields.Active" class="form-control select-down">
              <option value="1">{{ $t("USERS.ACTIVE_1") }}</option>
              <option value="0">{{ $t("USERS.ACTIVE_0") }}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordNew"
              :placeholder="$t('MY_PROFILE.PASSWORD_ONLY_TO_CHANGE')"
              :rules="[rules.emptyOrMin6]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW_CONFIRM") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordConfirm"
              :placeholder="$t('MY_PROFILE.PASSWORD_NEW_CONFIRM')"
              :rules="[rules.confirm]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row button-row">
          <button
            class="btn btn-pill btn-white button-form button-form-primary mr-9"
            @click="$router.push('/manager/users')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>

          <button
            ref="formProfileSubmit"
            class="btn btn-pill btn-tertiary button-form"
            :disabled="!Valid"
            @click="onSubmitProfile"
          >
            {{ $t("GENERIC.SAVE") }}
          </button>
        </div>
      </v-form>

      <div
        class="manager-subtitle"
        :class="[Companies.length === 0 ? 'text-danger' : '']"
      >
        {{
          $tc("USERS.LINKED_COMPANIES", Companies.length, {
            count: Companies.length
          })
        }}
      </div>

      <v-simple-table
        v-if="Companies.length > 0"
        class="white-table shadow-box mb-6"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                {{ $t("GENERIC.CREATED_AT") }}
              </th>
              <th>
                {{ $t("USERS.COMPANY") }}
              </th>
              <th>
                {{ $t("MY_PROFILE.ROLE") }}
              </th>
              <th>
                {{ $t("MY_PROFILE.FULLNAME") }}
              </th>
              <th>
                {{ $t("MY_PROFILE.PHONE") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in Companies" :key="c.CompanyID">
              <td>
                {{ formatDatetimeWithoutZone(c.CreatedAt) }}
              </td>
              <td>
                {{ c.CompanyName }}
              </td>
              <td>
                {{ $t("ROLE_NAMES_BY_ID." + c.RoleID) }}
              </td>
              <td>
                {{ c.Name }}
              </td>
              <td>
                {{ c.Phone }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
  <!-- end::Users edit for Superadmin role-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "UsersSuperadminEdit",
  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"])
  },
  data() {
    return {
      UserID: 0,
      Valid: true,
      Companies: [],
      Fields: {
        Email: "",
        Active: 1,
        PasswordNew: "",
        PasswordConfirm: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        emptyOrMin6: value => {
          if (!value || value.length === 0 || value.length >= 6) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.MIN_LENGTH_FIELD") + " 6";
          }
        },
        confirm: value =>
          value === this.Fields.PasswordNew ||
          this.$i18n.t("MY_PROFILE.PASSWORD_MISMATCH")
      }
    };
  },
  mounted() {
    this.UserID = parseInt(this.$route.params.id);
    this.loadUserData();
  },
  methods: {
    loadUserData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      UserService.getSomeUserProfile(this.UserID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/users");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }
          this.Companies = response.Companies;
        }

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
    },

    onSubmitProfile(e) {
      e.preventDefault();

      if (this.$refs.formUserEdit.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send update request
        UserService.updateSomeUserProfile(this.UserID, this.Fields).then(
          statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 412) {
                whyError = this.$i18n.t("MY_PROFILE.ERROR_412");
              } else if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
            this.Fields.PasswordNew = "";
            this.Fields.PasswordConfirm = "";
          }
        );
      }
    },

    formatDatetimeWithoutZone(datetime) {
      //return datetime;
      return moment(datetime).format("DD/MM/YYYY HH:mm:ss");
    }
  }
};
</script>
